import axios from 'axios'
import qs from 'qs'
// import store from '@/store'
import {VueAxios} from './axios'

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 30000 // 请求超时时间30秒
})

// 异常拦截处理器
const errorHandler = (error) => {
    console.log('error', error)
    if (error.response) {
        const data = error.response.data
        this.$notify({
            title: '错误',
            message: data.message ? data.message : '请求出现错误，请稍后再试'
        })
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    if (config.method === 'post' && config.headers['Content-Type'] === 'multipart/form-data') {
        return config
    }

    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    if (config.method !== 'get') {
        config.data = qs.stringify(config.data, {allowDots: true})
    }

    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    const data = response.data
    const code = data.status
    switch (code) {
        case 200:
            return data
        // case 401:
        //   store.dispatch('Logout').then(() => {
        //     setTimeout(() => {
        //       window.location.reload()
        //     }, 1500)
        //   })
        //   break
        case 500:
            this.$notify({
                title: '错误',
                message: data.msg ? data.msg : '请求出现错误，请稍后再试'
            })
            break
        default:
            return data
    }
}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}
