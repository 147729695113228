import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/index',
        meta: {title: '皮纹之家', keepAlive: true}
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "Index" */ '../views/Index'),
        meta: {title: '皮纹之家', keepAlive: true}
    },
    {
        path: '/case/index',
        name: 'caseIndex',
        component: () => import(/* webpackChunkName: "Index" */ '../views/case/Index'),
        meta: {title: '案例分析', keepAlive: true}
    },
    {
        path: '/case/detail',
        name: 'caseDetail',
        component: () => import(/* webpackChunkName: "Index" */ '../views/case/Detail'),
        meta: {title: '案例分析', keepAlive: true}
    },
    {
        path: '/case/add',
        name: 'caseAdd',
        component: () => import(/* webpackChunkName: "Index" */ '../views/case/Add'),
        meta: {title: '案例分析', keepAlive: true}
    },
    {
        path: '/case/edit',
        name: 'caseEdit',
        component: () => import(/* webpackChunkName: "Index" */ '../views/case/Edit'),
        meta: {title: '案例分析', keepAlive: true}
    },
    {
        path: '/doc/index',
        name: 'docIndex',
        component: () => import(/* webpackChunkName: "Index" */ '../views/doc/Index'),
        meta: {title: '资料下载', keepAlive: true}
    },
    {
        path: '/doc/detail',
        name: 'docDetail',
        component: () => import(/* webpackChunkName: "Index" */ '../views/doc/Detail'),
        meta: {title: '资料下载', keepAlive: true}
    },
    {
        path: '/doc/add',
        name: 'docAdd',
        component: () => import(/* webpackChunkName: "Index" */ '../views/doc/Add'),
        meta: {title: '资料下载', keepAlive: true}
    },
    {
        path: '/user/index',
        name: 'userIndex',
        component: () => import(/* webpackChunkName: "Index" */ '../views/user/Index'),
        meta: {title: '用户', keepAlive: true}
    },
    {
        path: '/user/home',
        name: 'userHome',
        component: () => import(/* webpackChunkName: "Index" */ '../views/user/Home'),
        meta: {title: '用户首页', keepAlive: true}
    },
    {
        path: '/user/message',
        name: 'userMessage',
        component: () => import(/* webpackChunkName: "Index" */ '../views/user/Message'),
        meta: {title: '用户消息', keepAlive: true}
    },

]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
